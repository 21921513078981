import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVariableData, selectLocalVariableData } from '../../store/page/selectors';
import ContainerSkeleton from '../sceleton/ContainerSceleton';
import { formValuesRequest, submitFormRequest, mergeStyleRequest, pageRequest, updateVariableRequest } from '../../store/page/actions';
import { combineClassNames, replacePlaceholdersWithVariablesV2, handleOpenUrl, getSizesBasedOnScreen, executeFunction, getNestedValue } from '../../utils/base';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import camelCase from 'lodash.camelcase';
import merge from 'lodash.merge';
import 'daterangepicker';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import {
  DateTimePickerInput,
  StyleUpdateChunk, RequestUpdateChunk, FunctionUpdateChunk,
  Breakpoints,
} from '../../types/base';
import generateComponentStyles from '../../utils/styleHelper';
import * as styles from './DateRangePicker.module.scss';

import './DateRangePicker.scss';

const ranges = {
  'Сьогодні': [moment().startOf('day'), moment().endOf('day')],
  'Вчора': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
  'Поточний тиждень': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  'Минулий тиждень': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
  'Поточний місяць': [moment().startOf('month'), moment().endOf('month')],
  'Минулий місяць': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Поточний рік': [moment().startOf('year'), moment().endOf('year')],
  'Минулий рік': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  'Весь час': [moment().startOf('year'), moment().endOf('year')],
}

const transformKeysDeep = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => transformKeysDeep(item));
  }
  
  if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      const newKey = camelCase(key);
      acc[newKey] = transformKeysDeep(value);
      return acc;
    }, {});
  }
  
  return obj;
};

const StyledInput = styled.input`
    z-index: 1;
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
`;

const validateInputPattern = (value: string, pattern?: string): boolean => {
  if (!pattern) return true;
  try {
    const regex = new RegExp(pattern);
    return regex.test(value);
  } catch (e) {
    console.error('Invalid regex pattern:', e);
    return true; // Return true if pattern is invalid to avoid blocking input
  }
};

interface Ranges {
  [key: string]: [Moment, Moment];
}

interface DateTimePickerInputType extends DateTimePickerInput {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  pageSettings?: any;
  parentRef?: any;
}

const getTimeRanges = (inputValue: string, ranges?: Ranges) => {
  let start: Moment | undefined;
  let end: Moment | undefined;

  if (inputValue.includes(':')) {
    // Custom range with unix timestamps
    const [startUnix, endUnix] = inputValue.split(':').map(Number);
    start = moment.unix(startUnix);
    end = moment.unix(endUnix);
  } else if (inputValue.length === 10) {
    // Single value with unix timestamp
    const startUnix = Number(inputValue);
    start = moment.unix(startUnix);
    end = start;
  } else {
    // Predefined ranges
    switch (inputValue) {
      case '1d':
        [start, end] = ranges['1 Day'];
        break;
      case '1w':
        [start, end] = ranges['1 Week'];
        break;
      case '2w':
        [start, end] = ranges['2 Weeks'];
        break;
      case '1M':
        [start, end] = ranges['1 Month'];
        break;
      default:
        break; // Invalid input, do nothing
    }
  }

  return { start, end };
}

const labelMatcher: { [key: string]: string } = {
  '1d': '1 Day',
  '1w': '1 Week',
  '2w': '2 Weeks',
  '1M': '1 Month',
}

const DateTimePicker: React.FC<DateTimePickerInputType> = ({
  id, style: stDraft, placeholder,
  value,
  tooltip,
  events: eventsDraft,
  soc,
  pageSettings,
  dataLake,
  activeState: activeStateDraft,
  regexp,
  settings,
  nestedTable,
  parentIndex,
  styleChunks,
  parentRef,
  sceleton: renderSceleton,
}) =>   {
  const inputRef = useMemo(() => {
    if (parentRef?.current) {
      return parentRef;
    }
    return React.createRef<HTMLInputElement>();
  }, [parentRef?.current]);
const { delay = 500, json } = settings || {};
const transformedJSON = json ? transformKeysDeep(json) : {};
const variableData = useSelector(selectVariableData);
const localVariableData = useSelector(selectLocalVariableData);
const { style: styleDraft, events, activeState } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft, activeState: activeStateDraft }, dataLake.data) : { style: stDraft, events: eventsDraft, activeState: activeStateDraft };
const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
  if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
    return merge({}, style, chunks[id][index]);
  }
  return style;
}, [id]);
// First useMemo to merge active state styles with base style
const mergedStyleWithState = useMemo(() => {
  let mergedStyle = styleDraft;
  if (activeState && styleDraft?.states?.[activeState]) {
    mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
  }

  // Handle states in breakpoints
  if (styleDraft?.breakpoints) {
    mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
      const typedBreakpointStyle = breakpointStyle as Breakpoints;
      if (typedBreakpointStyle?.states?.[activeState]) {
        acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
      } else {
        acc[breakpoint] = typedBreakpointStyle;
      }
      return acc;
    }, {} as typeof styleDraft.breakpoints);
  }

  return mergedStyle;
}, [styleDraft, activeState]);

// Second useMemo to merge styles with chunks based on parentIndex
const style = useMemo(() => {
  return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
}, [mergedStyleWithState, styleChunks, parentIndex]);
const baseScreenResolution =  pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;
const { autofocus } = style || {};
const dispatch = useDispatch();

const internalFns = {
  $setVariable: (key: string, value: any) => {
    dispatch(updateVariableRequest({ variable: key, value }));
  },
    $getVariable: (key: string) => {
      const value = getNestedValue(variableData, key) || getNestedValue(localVariableData, key);
      return value;
    },
  $moment: (...args: any[]) => moment(...args)
};
const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
const { 
  on_focus: focusStyles, 
  on_change: changeStyles, 
  on_blur: blurStyles, 
  on_launch: launchUpdates,
  on_hover: hoverUpdates,
  on_unhover: unhoverUpdates,
  on_regexp_success: regexpSuccessUpdates,
  on_regexp_error: regexpErorUpdates,
  on_outclick: outclickUpdates,
} = events || {};

const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (activeState === 'disabled' || activeState === 'loading') {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
  const newValue = e.target.value;

      // Check max length
  if (regexp?.max_length && newValue.length > regexp.max_length) {
    return;
  }
  setInternalInputValue(newValue);
  dispatch(formValuesRequest({ field: { id, value: newValue } }));
  // Check pattern validation
  if (regexp?.pattern && typeof regexp.pattern === 'string') {
    const result = !validateInputPattern(newValue, regexp.pattern);
    if (regexpSuccessUpdates && result) {
        const styleUpdates = regexpSuccessUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
        const requestUpdates = regexpSuccessUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
        const functionUpdates = regexpSuccessUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
        if (styleUpdates.length) {
          dispatch(mergeStyleRequest(styleUpdates));
        }
        if (requestUpdates.length) {
          requestUpdates.forEach((update) => {
            const { method, value, silent, config } = update?.request || {};
            const { delay } = config || {};
            if (update?.request && method === 'command') {
              dispatch(pageRequest({ 
                location: { pathname: value }, 
                silent,
                componentId: id, 
              }));
            } else if (update?.request && method === 'action') {
              dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            } else if (update?.request && method === 'open-url') {
              handleOpenUrl(value, delay, silent);
            }
          });
        }
        if (functionUpdates.length) {
          functionUpdates.forEach((update) => {
            if (update?.function) {
              executeFunction(update.function, e, internalFns);
            }
          });
        }
    } else if (regexpErorUpdates && !result) {
        const styleUpdates = regexpErorUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
        const requestUpdates = regexpErorUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
        const functionUpdates = regexpErorUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
        if (styleUpdates.length) {
          dispatch(mergeStyleRequest(styleUpdates));
        }
        if (requestUpdates.length) {
          requestUpdates.forEach((update) => {
            const { method, value, silent, config } = update?.request || {};
            const { delay } = config || {};
            if (update?.request && method === 'command') {
              dispatch(pageRequest({ 
                location: { pathname: value }, 
                silent,
                componentId: id,
              }));
            } else if (update?.request && method === 'action') {
              dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            } else if (update?.request && method === 'open-url') {
              handleOpenUrl(value, delay, silent);
            }
          });
        }
        if (functionUpdates.length) {
          functionUpdates.forEach((update) => {
            if (update?.function) {
              executeFunction(update.function, e, internalFns);
            }
          });
        }
    }
  }
  if (changeStyles) {
    const styleUpdates = changeStyles.filter((update): update is StyleUpdateChunk => 'style' in update);
    const requestUpdates = changeStyles.filter((update): update is RequestUpdateChunk => 'request' in update);
    const functionUpdates = changeStyles.filter((update): update is FunctionUpdateChunk => 'function' in update);
    if (styleUpdates.length) {
      dispatch(mergeStyleRequest(styleUpdates));
    }
    if (requestUpdates.length) {
      requestUpdates.forEach((update) => {
        const { method, value, silent, config } = update?.request || {};
        const { delay } = config || {};
        if (update?.request && method === 'command') {
          dispatch(pageRequest({ 
            location: { pathname: value }, 
            silent,
            componentId: id,
          }));
        } else if (update?.request && method === 'action') {
          dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
        } else if (update?.request && method === 'open-url') {
          handleOpenUrl(value, delay, silent);
        }
      });
    }
    if (functionUpdates.length) {
      functionUpdates.forEach((update) => {
        if (update?.function) {
          executeFunction(update.function, e, internalFns);
        }
      });
    }
  }
  if (soc) {
    dispatch(submitFormRequest({ value: 'soc', delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
  }
};

const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
  if (activeState === 'disabled' || activeState === 'loading') {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
  if (focusStyles) {
    const styleUpdates = focusStyles.filter((update): update is StyleUpdateChunk => 'style' in update);
    const requestUpdates = focusStyles.filter((update): update is RequestUpdateChunk => 'request' in update);
    const functionUpdates = focusStyles.filter((update): update is FunctionUpdateChunk => 'function' in update);
    if (styleUpdates.length) {
      dispatch(mergeStyleRequest(styleUpdates));
    }
    if (requestUpdates.length) {
      requestUpdates.forEach((update) => {
        const { method, value, silent, config } = update?.request || {};
        const { delay } = config || {};
        if (update?.request && method === 'command') {
          dispatch(pageRequest({ 
            location: { pathname: value }, 
            silent,
            componentId: id,
          }));
        } else if (update?.request && method === 'action') {
          dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
        } else if (update?.request && method === 'open-url') {
          handleOpenUrl(value, delay, silent);
        }
      });
    }
    if (functionUpdates.length) {
      functionUpdates.forEach((update) => {
        if (update?.function) {
          executeFunction(update.function, e, internalFns);
        }
      });
    }
  }
};

const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
  if (activeState === 'disabled' || activeState === 'loading') {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
  if (blurStyles) {
    const styleUpdates = blurStyles.filter((update): update is StyleUpdateChunk => 'style' in update);
    const requestUpdates = blurStyles.filter((update): update is RequestUpdateChunk => 'request' in update);
    const functionUpdates = blurStyles.filter((update): update is FunctionUpdateChunk => 'function' in update);
    if (styleUpdates.length) {
      dispatch(mergeStyleRequest(styleUpdates));
    }
    if (requestUpdates.length) {
      requestUpdates.forEach((update) => {
        const { method, value, silent, config } = update?.request || {};
        const { delay } = config || {};
        if (update?.request && method === 'command') {
          dispatch(pageRequest({ 
            location: { pathname: value }, 
            silent,
            componentId: id, 
          }));
        } else if (update?.request && method === 'action') {
          dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
        } else if (update?.request && method === 'open-url') {
          handleOpenUrl(value, delay, silent);
        }
      });
    }
    if (functionUpdates.length) {
      functionUpdates.forEach((update) => {
        if (update?.function) {
          executeFunction(update.function, e, internalFns);
        }
      });
  }
}
};

const handleHover = (e: React.MouseEvent<HTMLTextAreaElement>) => {
  if(activeState === 'disabled' || activeState === 'loading') {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
  if (hoverUpdates) {
    const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
    const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
    const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
    if (styleUpdates.length) {
      dispatch(mergeStyleRequest(styleUpdates));
    }
    if (requestUpdates.length) {
      requestUpdates.forEach((update) => {
        const { method, value, silent, config } = update?.request || {};
        const { delay } = config || {};
        if (update?.request && method === 'action') {
          dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
        } else if (update?.request && method === 'open-url') {
          handleOpenUrl(value, delay, silent);
        }
      });
    }

    if (functionUpdates.length) {
      functionUpdates.forEach((update) => {
        if (update?.function) {
          executeFunction(update.function, e, internalFns);
        }
      });
    }
  }

}

const handleUnhover = (e:  React.MouseEvent<HTMLTextAreaElement>) => {
  if(activeState === 'disabled' || activeState === 'loading') {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
  if (unhoverUpdates) {
    const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
    const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
    const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
    if (styleUpdates.length) {
      dispatch(mergeStyleRequest(styleUpdates));
    }
    if (requestUpdates.length) {
      requestUpdates.forEach((update) => {
        const { method, value, silent, config } = update?.request || {};
        const { delay } = config || {};
        if (update?.request && method === 'action') {
          dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
        } else if (update?.request && method === 'open-url') {
          handleOpenUrl(value, delay, silent);
        }
      });
    }
    if (functionUpdates.length) {
      functionUpdates.forEach((update) => {
        if (update?.function) {
          executeFunction(update.function, e, internalFns);
        }
      });
    }
  }
}

useEffect(() => {
  if (launchUpdates && launchUpdates.length) {
    const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
    const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
    if (requestUpdates.length) {
      requestUpdates.forEach((update) => {
        const { method, value, silent, config } = update?.request || {};
        const { delay } = config || {};
        if (update?.request && method === 'action') {
          const newTimeout = setTimeout(() => {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          }, config?.delay * 1000 || 0);

          timeoutsRef.current.push(newTimeout);
        } else if (update?.request && method === 'open-url') {
          handleOpenUrl(value, delay, silent);
        }
      });
    }
    if (functionUpdates.length) {
      functionUpdates.forEach((update) => {
        if (update?.function) {
          executeFunction(update.function);
        }
      });
    }
  }

  return () => {
    timeoutsRef?.current?.forEach((timeout) => {
      clearTimeout(timeout);
    });
  };
}, [launchUpdates]);

useEffect(() => {
  const handleUnclick = (e: MouseEvent) => {
    if (activeState === 'disabled' || activeState === 'loading') return;

    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      if (outclickUpdates) {
        const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
        const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
        const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);

        if (styleUpdates.length) {
          dispatch(mergeStyleRequest(styleUpdates, parentIndex));
        }

        if (requestUpdates.length) {
          requestUpdates.forEach((update) => {
            const { method, value, silent, config } = update?.request || {};
            const { delay } = config || {};
            if (update?.request && method === 'action') {
              dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            } else if (update?.request && method === 'open-url') {
              handleOpenUrl(value, delay, silent);
            }
          });
        }

        if (functionUpdates.length) {
          functionUpdates.forEach((update) => {
            if (update?.function) {
              executeFunction(update.function);
            }
          });
        }
      }
    }
  };

  if (outclickUpdates) {
    document.addEventListener('mousedown', handleUnclick);
    return () => {
      document.removeEventListener('mousedown', handleUnclick);
    };
  }
  return undefined;
}, [parentIndex, activeState, inputRef.current]);

const [startDate, setStartDate] = useState<Moment | undefined>();
const [endDate, setEndDate] = useState<Moment | undefined>();
const [internalInputValue, setInternalInputValue] = useState<string>('');
const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

useEffect(() => {
  if (value) {
    const { start, end } = getTimeRanges(value);

      // Always update internal value when external value changes
      if (value.includes(':')) {
        setInternalInputValue(`${start?.format('HH:mm DD/MM/YY')} - ${end?.format('HH:mm DD/MM/YY')}`);
      } else if (value.length === 10) {
        setInternalInputValue(start?.format('HH:mm DD/MM/YY') || '');
      } else {
        setInternalInputValue(labelMatcher[value]);
      }
    } else {
      // Clear internal value when external value is cleared
      setInternalInputValue('');
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.dates = [];
      }
    }
}, [value]);

useEffect(() => {
  const dateRangePickerContainer = document.createElement('div');
  dateRangePickerContainer.id = `dateRangePickerContainer-${id}`;
  document.body.appendChild(dateRangePickerContainer);
  const { start: startUnix, end: endUnix } = getTimeRanges(value);
  const $dateRangePicker = $(inputRef.current).daterangepicker({
    startDate: startUnix,
    endDate: endUnix,
    ranges,
    cancelButtonClasses: styles.cancelBtn,
    showCustomRangeLabel: false,
    applyButtonClasses: styles.applyBtn,
    timePicker: true,
    timePicker24Hour: true,
    timePickerSeconds: false,
    alwaysShowCalendars: true,
    opens: 'left',
    autoUpdateInput: false,
    maxDate: moment().endOf('day'),
    locale: {
      format: 'DD/MM/YYYY',
      separator: ' : ',
      applyLabel: 'Save',
      cancelLabel: 'Cancel',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Custom',
      weekLabel: 'W',
      daysOfWeek: ['Sub', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
      ],
      firstDay: 1
    },
    ...(transformedJSON || {}),
  }, (start: Moment, end: Moment, label: string) => {
    if (label === '1 Day' || label === '1 Week' || label === '2 Weeks' || label === '1 Month') {
      setInternalInputValue(label);
    } else if (transformedJSON?.singleDatePicker) {
      setInternalInputValue(start.format(transformedJSON.locale?.format));
    } else {
      setInternalInputValue(`${start.format(transformedJSON.locale?.format)} - ${end.format(transformedJSON.locale?.format)}`);
    }

        // Create and dispatch a synthetic input event
        const syntheticEvent = {
          target: {
            value: label === '1 Day' || label === '1 Week' || label === '2 Weeks' || label === '1 Month' 
              ? label 
              : transformedJSON?.singleDatePicker 
                ? start.format(transformedJSON.locale?.format)
                : `${start.format(transformedJSON.locale?.format)} - ${end.format(transformedJSON.locale?.format)}`
          },
          preventDefault: () => {},
          stopPropagation: () => {}
        } as React.ChangeEvent<HTMLTextAreaElement>;
    
        // Call handleInput with the synthetic event
        handleInput(syntheticEvent);
  });

  $dateRangePicker.on('show.daterangepicker', () => {
    setIsCalendarOpen(true);
  });

  $dateRangePicker.on('hide.daterangepicker', () => {
    setIsCalendarOpen(false);
  });

  // Cleanup event listeners on component unmount
  return () => {
    $dateRangePicker.off('show.daterangepicker');
    $dateRangePicker.off('hide.daterangepicker');
    document.body.removeChild(dateRangePickerContainer);
  };
}, [value, id]);

if (renderSceleton && style) {
  return <ContainerSkeleton 
    style={style}
    baseScreenResolution={baseScreenResolution}
    skipStates={activeState === 'disabled' || activeState === 'loading'}
  />;
}

return (
  <StyledInput
    ref={inputRef}
    style={{ display: style?.visibility === 'hidden' ? 'none' : undefined }}
    placeholder={placeholder}
    id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
    type={'text'}
    $shape={style}
    $baseScreenResolution={baseScreenResolution}
    $skipStates={activeState === 'disabled' || activeState === 'loading'}
    disabled={activeState === 'disabled' || activeState === 'loading'}
    data-component="input"
    className={combineClassNames(style.class)}
    value={internalInputValue}
    title={tooltip}
    autoFocus={autofocus}
    onInput={handleInput}
    onFocus={handleFocus}
    onBlur={handleBlur}
    onMouseEnter={handleHover}
    onMouseLeave={handleUnhover}
    maxLength={regexp?.max_length}
  />
);
};

export default DateTimePicker;