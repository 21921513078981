.fix_overflow * {
  // overflow: hidden !important;
  text-decoration: none !important;
  // text-overflow: initial !important;
}

.fix_overflow *:visited {
  text-decoration: none;
}

