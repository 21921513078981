import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import merge from 'lodash.merge';
import moment from 'moment';
import { selectVariableData, selectLocalVariableData } from '../../store/page/selectors';
import { pageRequest, submitFormRequest, mergeStyleRequest, updateVariableRequest } from '../../store/page/actions';
import { combineClassNames, extractValueInsideCurlyBraces, replacePlaceholdersWithVariablesV2, handleOpenUrl, getStringValueFromPath, getSizesBasedOnScreen, executeFunction, getNestedValue } from '../../utils/base';
import { InlineText as Type } from '../../types/base';
import { StyleUpdateChunk, RequestUpdateChunk, FunctionUpdateChunk, Breakpoints } from '../../types/base';
import generateComponentStyles from '../../utils/styleHelper';
import parse from 'html-react-parser';
import MarkdownContent from '../../chunks/MarkdownContent';

import styles from './Text.module.scss';

const addLineBreaksAndWrapChunks = (html) => {
  return html.replace(/(<[^>]+>)([^<]+)(<\/[^>]+>)/g, (match, p1, p2, p3) => {
    const wrappedText = p2.split('\n').map(chunk => `<span class="shimmer-text">${chunk}</span>`).join('<br/>');
    return `${p1}${wrappedText}${p3}`;
  });
};

// Function to wrap text nodes with <span class="shimmer-text"></span>
function transformHtmlString(htmlString: string, parentWidth: number) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  function wrapTextNodes(node: Node) {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== '') {
      const textContent = node.nodeValue.split(/(\s+|\n)/); // Split by spaces and line breaks
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.whiteSpace = 'nowrap';
      document.body.appendChild(span);

      let currentText = '';
      let wrappedText = '';
      let lineBreaks = 0;

      textContent.forEach((token, index) => {
        if (token === '\n') {
          wrappedText += `<span class="shimmer-text">${currentText.trim()}</span><br/>`;
          currentText = '';
          lineBreaks++;
        } else {
          const testText = currentText ? `${currentText}${token}` : token;
          span.textContent = testText;

          if (span.offsetWidth > parentWidth) {
            wrappedText += `<span class="shimmer-text">${currentText.trim()}</span><br/>`;
            currentText = token.trim();
            lineBreaks++;
          } else {
            currentText = testText;
          }
        }

        if (index === textContent.length - 1 && currentText) {
          wrappedText += `<span class="shimmer-text">${currentText.trim()}</span><br/>`;
        }
      });

      document.body.removeChild(span);
      const wrapperSpan = document.createElement('span');
      wrapperSpan.innerHTML = wrappedText;
      (node as ChildNode).replaceWith(wrapperSpan);

    } else if (node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach(wrapTextNodes);
    }
  }

  doc.body.childNodes.forEach(node => wrapTextNodes(node));

  return doc.body.innerHTML;
}

const StyledInlineText = styled.div<{
  $shape?: any;
  $baseScreenResolution?: number;
  $skipStates?: boolean;
}>`
  z-index: 1;
  position: relative;
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}

  ${({ $shape }) => 
    $shape?.font?.text_overflow === 'ellipsis' && css`      
      /* Apply to all nested elements */
      * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `
  }
`;

interface InlineTextProps extends Type {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
}

const InlineText: React.FC<InlineTextProps & { pageSettings: any }> = ({
  id, value, style: stDraft, events: eventsDraft, dataLake,
  pageSettings,
  activeState: activeStateDraft,
  nestedTable,
  parentIndex,
  styleChunks,
  sceleton: renderSceleton,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { style: styleDraft, events, activeState } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft, activeState: activeStateDraft }, dataLake.data) : { style: stDraft, events: eventsDraft, activeState: activeStateDraft };
  const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
    if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
      return merge({}, style, chunks[id][index]);
    }
    return style;
  }, [id]);
  const variableData = useSelector(selectVariableData);
  const localVariableData = useSelector(selectLocalVariableData);
  // Merge active state styles with base style
  // First useMemo to merge active state styles with base style
  const mergedStyleWithState = useMemo(() => {
    let mergedStyle = styleDraft;
    if (activeState && styleDraft?.states?.[activeState]) {
      mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
    }

    // Handle states in breakpoints
    if (styleDraft?.breakpoints) {
      mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
        const typedBreakpointStyle = breakpointStyle as Breakpoints;
        if (typedBreakpointStyle?.states?.[activeState]) {
          acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
        } else {
          acc[breakpoint] = typedBreakpointStyle;
        }
        return acc;
      }, {} as typeof styleDraft.breakpoints);
    }

    return mergedStyle;
  }, [styleDraft, activeState]);

  // Second useMemo to merge styles with chunks based on parentIndex
  const style = useMemo(() => {
    return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
  }, [mergedStyleWithState, styleChunks, parentIndex]);
  const baseScreenResolution = pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;
  const dispatch = useDispatch();
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);

  const internalFns = {
    $setVariable: (key: string, value: any) => {
      dispatch(updateVariableRequest({ variable: key, value }));
    },
    $getVariable: (key: string) => {
      const value = getNestedValue(variableData, key) || getNestedValue(localVariableData, key);
      return value;
    },
    $moment: (...args: any[]) => moment(...args)
  };
  const {
    on_click: clickUpdates,
    on_launch: launchUpdates,
    on_hover: hoverUpdates,
    on_unhover: unhoverUpdates,
    on_outclick: outclickUpdates,
  } = events || {};
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (clickUpdates) {
      const styleUpdates = clickUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = clickUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = clickUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ 
              location: { pathname: value }, 
              silent,
              componentId: id,
            }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (hoverUpdates) {
      const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  const handleUnhover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    if (unhoverUpdates) {
      const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }

      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (launchUpdates && launchUpdates.length) {
      const styleUpdates = launchUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            // const newTimeout = setTimeout(() => {
            //   dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
            // }, config?.delay * 1000 || 0);

            // timeoutsRef.current.push(newTimeout);
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }

      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }

    return () => {
      timeoutsRef?.current?.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [launchUpdates]);

  useEffect(() => {
    const handleUnclick = (e: MouseEvent) => {
      if (activeState === 'disabled' || activeState === 'loading') return;

      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        if (outclickUpdates) {
          const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
          const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
          const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);

          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates, parentIndex));
          }

          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, silent, delay, componentId: id }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }

          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, undefined, internalFns);
              }
            });
          }
        }
      }
    };

    if (outclickUpdates) {
      document.addEventListener('mousedown', handleUnclick);
      return () => {
        document.removeEventListener('mousedown', handleUnclick);
      };
    }
    return undefined;
  }, [parentIndex, activeState, containerRef.current]);

  const extractedValue = extractValueInsideCurlyBraces(value || '');
  const val = dataLake?.data && value.match(/{{this\..*}}/) ? getStringValueFromPath(dataLake.data, extractedValue) : value;

  if (renderSceleton) {
    const renderMarkdown = <MarkdownContent text={val} />;
    const htmlString = ReactDOMServer.renderToString(renderMarkdown);

  
    const wrappedHtmlString = transformHtmlString(htmlString, containerRef.current?.clientWidth || document.body.clientWidth)
  
    // const finalHtmlString = addLineBreaksAndWrapChunks(wrappedHtmlString);
    
    const WrappedMarkdownContent = () => parse(wrappedHtmlString);

    return  (<StyledInlineText
        ref={containerRef}
        id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
        $shape={style}
        $baseScreenResolution={baseScreenResolution}
        $skipStates={activeState === 'disabled' || activeState === 'loading'}
        data-component="text"
        className={combineClassNames(style.class, styles.fix_overflow)}
        onClick={handleClick}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
          <WrappedMarkdownContent />
      </StyledInlineText>);
  }


  return (
      <StyledInlineText
        ref={containerRef}
        id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
        $shape={style}
        $baseScreenResolution={baseScreenResolution}
        $skipStates={activeState === 'disabled' || activeState === 'loading'}
        data-component="text"
        className={combineClassNames(style.class)}
        onClick={handleClick}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
          <MarkdownContent text={val} />
      </StyledInlineText>
    );
}

export default InlineText;
